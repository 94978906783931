import './index.css';
import './components/product/product-css.css';
import 'antd/dist/reset.css';
import mixpanel from 'mixpanel-browser';

import { createRoot } from 'react-dom/client';
import * as React from 'react';

import { Modal } from 'antd';
import App from './App';
mixpanel.init(
  process.env.MIXPANEL_TOKEN || '3124dc80003694e7844ab7dbc5dc98fd',
  { debug: false }
);

const container = document.getElementById('arcsite-app')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

window.addEventListener('hashchange', () => {
  Modal.destroyAll();
});
window.__MOCK__ = false;

// migrate from hash router
if (window.location.hash) {
  const hash = window.location.hash.substring(1); // Remove '#'
  window.location.href = hash;
}
